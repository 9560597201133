<template>
    <div class="m-wapper" v-if="list.length">
        <img src="@/assets/images/p-icon.png" alt="" class="m-icon">
        <div class="content-warp">
            <div class="content-wapper">
                <div class="content-txt" ref="contentTxt"
                    :style="`--d:${item.title.length * 0.3 < 8 ? 8 : item.title.length * 0.3}s;--cs:${list.length == 1 ? 'infinite' : '1'}`"
                    v-if="active == index" v-for="item, index in list" :key="index" @animationend="animationend">{{
                    item.title }}</div>
            </div>
            <div @click="toPage(list[active].jump_url)" v-if="list[active].jump_url">
                <el-button class="button_blue btn" size="mini">{{ $t("了解更多") }}</el-button>
            </div>
        </div>
        <div class="icon-download" :class="{ iconAnimation }" @animationend="iconAnimationend" @click="downApp">
            <img src="@/assets/images/download-icon2.png" alt="">
        </div>

    </div>
</template>

<script>
import { notice_list_api } from "@/api";
import svgIcon from "@/components/svg.vue";
export default {
    data() {
        return {
            list: [],
            active: 0,
            flag: true,
            timeId: null,
            iconAnimation: true
        }
    },
    components: { svgIcon },
    async mounted() {
        await this.getData()
    },
    beforeDestroy() {
        clearTimeout(this.timeId)
    },
    methods: {
        iconAnimationend() {
            this.iconAnimation = false;
            setTimeout(() => this.iconAnimation = true, 1000)
        },
        toPage(url) {
            if (url.startsWith('http')) {
                location.href = url
            } else {
                this.$router.push(url)
            }
        },
        async getData() {
            const { data } = await notice_list_api({
                type: 1,
                show_loca: 1
            })
            if (data.code == 1) {
                this.list = data.data
            }
        },
        animationend() {
            if (this.flag) {
                this.active++
                if (this.active >= this.list.length) {
                    this.active = 0
                }
                this.flag = false
                this.timeId = setTimeout(() => {
                    this.flag = true
                }, 1000)
            }
        }
    }
}
</script>

<style scoped lang="less">
.m-wapper {
    height: 45px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @media (max-width:768px) {
        height: 38px;
        margin-bottom: 20px;
    }
}

.m-icon {
    width: 44px;
    height: 44px;
    @media (max-width:768px) {
        width: 32px;
        height: 32px;
    }
}

.btn {
    border-radius: 100vh;
    min-width: 85px;

    @media (max-width:768px) {
        min-width: 0;
    }
}

.content-wapper {
    flex: 1;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width:768px) {
        font-size: 14px;
    }
}

.content-txt {
    --d: 4s;
    --cs: infinite;
    --left: 1040px;
    animation: var(--d) an var(--cs) linear;
    display: inline-block;
    min-width: 100%;
    color: #FFF17B;

    @media (max-width :1040px) {
        --left: 100vw;
    }
}

@keyframes an {
    from {
        transform: translateX(var(--left));
    }

    to {
        transform: translateX(-100%);
    }
}

.icon-download {
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    @media (max-width:768px) {
        width: 44px;
        height: 44px;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.iconAnimation {
    animation: download-run .5s 3;
    animation-fill-mode: both;
}

@keyframes download-run {
    0% {
        transform: scale(1)
    }

    50% {
        transform: scale(1.2)
    }

    100% {
        transform: scale(1)
    }
}
.content-warp{
    flex: 1;
    display: flex;
    width: 0;
    align-items: center;
    background-color: #161F2C;
    height: 100%;
    padding: 0 10px;
    border-radius: 100vh;
    margin: 0 10px;
    @media (max-width:768px) {
        padding: 0 8px;
    }
}
</style>